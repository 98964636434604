import React from "react"
import { Helmet } from "react-helmet"

import { CheckIcon, XIcon, CheckCircleIcon } from '@heroicons/react/solid'

import Header from "src/components/headers/header"
import Ariane from "src/components/headers/ariane"
import Footer from "src/components/footers/footer"

const fil_ariane = ["Nos prestations ", "/prestations-demenagement/"]

const tiers = [
  {
    name: 'Mobilier Seul',
    id: 'mobilier-seul',
    href: '#',
    featured: false,
    description: '',
    mainFeatures: ['Basic invoicing', 'Easy to use accounting', 'Mutli-accounts'],
  },
  {
    name: 'Dynamic',
    id: 'dynamic',
    href: '#',
    featured: false,
    description: '',
    mainFeatures: ['Basic invoicing', 'Easy to use accounting', 'Mutli-accounts'],
  },
  {
    name: 'Dynamic+',
    id: 'dynamic+',
    href: '#',
    featured: false,
    description: '',
    mainFeatures: ['Basic invoicing', 'Easy to use accounting', 'Mutli-accounts'],
  },
  {
    name: 'Confort',
    id: 'confort',
    href: '#',
    featured: false,
    description: '',
    mainFeatures: ['Basic invoicing', 'Easy to use accounting', 'Mutli-accounts'],
  },
  {
    name: 'Confort+',
    id: 'confort+',
    href: '#',
    featured: false,
    description: '',
    mainFeatures: ['Basic invoicing', 'Easy to use accounting', 'Mutli-accounts'],
  },
  {
    name: 'Grand Confort',
    id: 'grand-confort',
    href: '#',
    featured: false,
    description: '',
    mainFeatures: ['Basic invoicing', 'Easy to use accounting', 'Mutli-accounts'],
  },
]
const sections = [
  {
    name: 'AVANT LE DÉMÉNAGEMENT : PRÉPARATION',
    features: [
      { name: "Retrait des emballages par vos soins à l’agence de Tassin (la mise à disposition de cartons et d’adhésifs est comprise dans notre tarification dans la limite de l’estimation du commercial)", tiers: { Dynamic: true, "Dynamic+": true, Confort: true, "Confort+": true, "Grand Confort": true, "Mobilier Seul": false } },
    ],
  },
  {
    name: 'AU DÉPART : CONDITIONNEMENT DE VOS EFFETS',
    features: [
      { name: 'Protection de vos matelas et sommiers sous housse à usage unique', tiers: { Dynamic: true, Confort: true, "Confort+": true, "Dynamic+": true, "Grand Confort": true, "Mobilier Seul": true } },
      { name: 'Protection de vos meubles', tiers: { Confort: true, "Confort+": true, Dynamic: true, "Dynamic+": true, "Grand Confort": true, "Mobilier Seul": true } },
      { name: 'Démontage de vos meubles (si nécessaire)', tiers: { Confort: true, "Confort+": true, Dynamic: false, "Dynamic+": true, "Grand Confort": true, "Mobilier Seul": true } },
      { name: 'Emballage de votre télévision, Hi-Fi, micro-informatique, vidéo', tiers: { Confort: true, "Confort+": true, Dynamic: false, "Dynamic+": false, "Grand Confort": true, "Mobilier Seul": false } },
      { name: 'Emballage de vos vêtements sur cintres', tiers: { Confort: true, "Confort+": true, Dynamic: false, "Dynamic+": false, "Grand Confort": true, "Mobilier Seul": false } },
      { name: 'Emballage de votre vaisselle et de vos objets fragiles (bibelots, verrerie, etc.)', tiers: { Confort: true, "Confort+": true, Dynamic: false, "Dynamic+": false, "Grand Confort": true, "Mobilier Seul": false } },
      { name: 'Emballage de vos livres, CD, DVD', tiers: { Confort: false, "Confort+": true, Dynamic: false, "Dynamic+": false, "Grand Confort": true, "Mobilier Seul": false } },
      { name: 'Emballage des matériels et objets divers non fragiles', tiers: { Confort: false, "Confort+": true, Dynamic: false, "Dynamic+": false, "Grand Confort": true, "Mobilier Seul": false } },
      { name: 'Emballage de votre linge de maison et de vos vêtements pliés', tiers: { Confort: false, "Confort+": true, Dynamic: false, "Dynamic+": false, "Grand Confort": true, "Mobilier Seul": false } },
      { name: 'Décrochage des rideaux, voilages, tentures', tiers: { Confort: "En option", "Confort+": 'En option', Dynamic: false, "Dynamic+": false, "Grand Confort": 'En option', "Mobilier Seul": false } },
      { name: 'Décrochage des lustres et miroirs, cadres, tableaux non scellés', tiers: { Confort: "En option", "Confort+": 'En option', Dynamic: false, "Dynamic+": false, "Grand Confort": 'En option', "Mobilier Seul": false } },
    ],
  },
  {
    name: 'MANUTENTION ET TRANSPORT DE VOTRE MOBILIER',
    features: [
      { name: 'Chargement du camion', tiers: { Confort: true, "Confort+": true, Dynamic: true, "Dynamic+": true, "Grand Confort": true, "Mobilier Seul": true } },
      { name: 'Calage, arrimage et transport', tiers: { Confort: true, "Confort+": true, Dynamic: true, "Dynamic+": true, "Grand Confort": true, "Mobilier Seul": true } },
      { name: 'Déchargement du camion et emménagement', tiers: { Confort: true, "Confort+": true, Dynamic: true, "Dynamic+": true, "Grand Confort": true, "Mobilier Seul": true } },
    ],
  },
  {
    name: 'À DESTINATION : DÉBALLAGE DE VOS EFFETS',
    features: [
      { name: 'Mise en place de votre mobilier selon vos directives', tiers: { Confort: true, "Confort+": true, Dynamic: true, "Dynamic+": true, "Grand Confort": true, "Mobilier Seul": true } },
      { name: 'Remontage de vos meubles (quand ils ont été démontés par nos soins)', tiers: { Confort: true, "Confort+": true, Dynamic: false, "Dynamic+": true, "Grand Confort": true, "Mobilier Seul": true } },
      { name: 'Déballage de vos vêtements sur cintres', tiers: { Confort: true, "Confort+": true, Dynamic: false, "Dynamic+": false, "Grand Confort": true, "Mobilier Seul": false } },
      { name: 'Déballage de votre vaisselle et de vos objets fragiles (bibelots, verrerie, etc.)', tiers: { Confort: true, "Confort+": true, Dynamic: false, "Dynamic+": false, "Grand Confort": true, "Mobilier Seul": false } },
      { name: 'Déballage de votre télévision, Hi-Fi, micro-informatique, vidéo', tiers: { Confort: true, "Confort+": true, Dynamic: false, "Dynamic+": false, "Grand Confort": true, "Mobilier Seul": false } },
      { name: 'Déballage de votre linge de maison et de vos vêtements pliés', tiers: { Confort: false, "Confort+": false, Dynamic: false, "Dynamic+": false, "Grand Confort": true, "Mobilier Seul": false } },
      { name: 'Déballage des matériels et objets divers non fragiles', tiers: { Confort: false, "Confort+": false, Dynamic: false, "Dynamic+": false, "Grand Confort": true, "Mobilier Seul": false } },
      { name: 'Déballage de vos livres, CD, DVD', tiers: { Confort: false, "Confort+": false, Dynamic: false, "Dynamic+": false, "Grand Confort": true, "Mobilier Seul": false } },
      { name: 'Repose des rideaux, voilages, tentures', tiers: { Confort: "En option", "Confort+": "En option", Dynamic: false, "Dynamic+": false, "Grand Confort": "En option", "Mobilier Seul": false } },
      { name: 'Remise en place des lustres et miroirs, cadres, tableaux non scellés', tiers: { Confort: "En option", "Confort+": "En option", Dynamic: false, "Dynamic+": false, "Grand Confort": "En option", "Mobilier Seul": false } },
    ],
  },
  {
    name: 'OPTIONS & EXCLUSIONS',
    features: [
      { name: 'Démontage & remontage de meubles spéciaux (Lit pont, à baldaquin, superposé, armoire chevillée, etc.)', tiers: { Confort: "En option", "Confort+": "En option", Dynamic: "En option", "Dynamic+": "En option", "Grand Confort": "En option", "Mobilier Seul": "En option" } },
      { name: 'Assemblage de meubles neufs en kit', tiers: { Confort: "En option", "Confort+": "En option", Dynamic: "En option", "Dynamic+": "En option", "Grand Confort": "En option", "Mobilier Seul": "En option" } },
      { name: 'Transport de vos plantes et végétaux (hors garantie, à la libre appréciation du déménageur)', tiers: { Confort: 'En option', "Confort+": 'En option', Dynamic: 'En option', "Dynamic+": "En option", "Grand Confort": "En option", "Mobilier Seul": "En option" } },
      { name: 'Transport d’une voiture ou d’une moto', tiers: { Confort: 'En option', "Confort+": 'En option', Dynamic: 'En option', "Dynamic+": "En option", "Grand Confort": "En option", "Mobilier Seul": "En option" } },
      { name: 'Décrochage et repose d’objets fixés aux murs, planchers et plafonds (Tringles, meubles, éléments de cuisine et/ou de salle de bain)', tiers: { Confort: "En option", "Confort+": "En option", Dynamic: "En option", "Dynamic+": "En option", "Grand Confort": "En option", "Mobilier Seul": "En option" } },
      { name: 'Déconnexion & reconnexion des appareils électroménagers, TV, audio, informatique, etc.', tiers: { Confort: false, "Confort+": false, Dynamic: false, "Dynamic+": false, "Grand Confort": false, "Mobilier Seul": false } },
      { name: 'Déconnexion & reconnexion des alimentations en eau, calage et vidange de vos appareils électroménagers (Lave-linge, sèche-linge, lave-vaisselle, réfrigérateur, congélateur, etc.)', tiers: { Confort: false, "Confort+": false, Dynamic: false, "Dynamic+": false, "Grand Confort": false, "Mobilier Seul": false } },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function index() {

  return (
    <div className="bg-art-beige">
      <Helmet>
        <title>Prestation Art Moval de déménagement pour Particuliers</title>
        <meta name="description" content="Formules Art Moval pour le démanagement de particuliers | Mobilier Seul, Dynamic, Dynamic+, Confort, Confort+, Grand Confort" />
      </Helmet>
      {/* <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Header />
      </header> */}

      <header className="sticky top-0 z-40 bg-white shadow-xl">
        <Header />
      </header>

      <main className="relative">
        <Ariane fil_ariane={fil_ariane} />

        <div className="isolate overflow-hidden">
          <div className="flow-root bg-art-beige pb-16 pt-6 sm:pt-0 lg:pb-16">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="relative z-10">
                <h1 className="mx-auto max-w-4xl text-center text-4xl font-bold tracking-tight text-art-marron-fonce sm:text-4xl lg:text-6xl">
                  Nos prestations
                </h1>
                <p className="mx-auto mt-4 max-w-2xl text-center text-lg leading-8 text-art-marron-fonce">
                  Un déménagement, c’est un nouveau départ. Nous sommes déménageurs près de Lyon depuis plus de 25 ans.
                  En travaillant avec nous, vous profitez d’un service serein et de déménageurs sérieux et expérimentés.
                  Faites appel à notre savoir-faire.
                </p>
                <p className="mx-auto mt-4 max-w-2xl text-center text-lg leading-8 text-art-marron-fonce">
                  <a
                    target="_blank"
                    href="/ArtMoval_Prestations_All_V3.pdf"
                    className="rounded-md bg-white borber-art-orange px-3.5 py-2.5 text-base font-semibold text-marron-fonce shadow-sm hover:bg-art-bleu focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-art-orange"
                  >
                    Télécharger notre plaquette
                  </a>
                  <a
                    href="/contact/"
                    className="hidden lg:inline ml-4 rounded-md  bg-art-orange px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-art-bleu focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-art-orange"
                  >
                    Demande de devis
                  </a>
                </p>

              </div>
              {/* <div className="relative mx-auto mt-10 grid max-w-md grid-cols-1 gap-y-8 lg:mx-0 lg:-mb-14 lg:max-w-none lg:grid-cols-3">
                        <svg
                        viewBox="0 0 1208 1024"
                        aria-hidden="true"
                        className="absolute -bottom-48 left-1/2 h-[64rem] -translate-x-1/2 translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] lg:-top-48 lg:bottom-auto lg:translate-y-0"
                        >
                        <ellipse cx={604} cy={512} fill="url(#d25c25d4-6d43-4bf9-b9ac-1842a30a4867)" rx={604} ry={512} />
                        <defs>
                            <radialGradient id="d25c25d4-6d43-4bf9-b9ac-1842a30a4867">
                            <stop stopColor="#7775D6" />
                            <stop offset={1} stopColor="#E935C1" />
                            </radialGradient>
                        </defs>
                        </svg>
                        <div
                        className="hidden lg:absolute lg:inset-x-px lg:bottom-0 lg:top-4 lg:block lg:rounded-t-2xl lg:bg-gray-800/80 lg:ring-1 lg:ring-white/10"
                        aria-hidden="true"
                        />
                        {tiers.map((tier) => (
                        <div
                            key={tier.id}
                            className={classNames(
                            tier.featured
                                ? 'z-10 bg-white shadow-xl ring-1 ring-gray-900/10'
                                : 'bg-gray-800/80 ring-1 ring-white/10 lg:bg-transparent lg:pb-14 lg:ring-0',
                            'relative rounded-2xl'
                            )}
                        >
                            <div className="p-8 lg:pt-12 xl:p-10 xl:pt-14">
                            <h3
                                id={tier.id}
                                className={classNames(
                                tier.featured ? 'text-gray-900' : 'text-white',
                                'text-sm font-semibold leading-6'
                                )}
                            >
                                {tier.name}
                            </h3>
                            <div className="flex flex-col gap-6 sm:flex-row sm:items-end sm:justify-between lg:flex-col lg:items-stretch">
                                <div className="mt-2 flex items-center gap-x-4">
                                
                                
                                </div>
                                <a
                                href={tier.href}
                                aria-describedby={tier.id}
                                className={classNames(
                                    tier.featured
                                    ? 'bg-indigo-600 shadow-sm hover:bg-indigo-500 focus-visible:outline-indigo-600'
                                    : 'bg-white/10 hover:bg-white/20 focus-visible:outline-white',
                                    'rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2'
                                )}
                                >
                                Buy this plan
                                </a>
                            </div>
                            <div className="mt-8 flow-root sm:mt-10">
                                <ul
                                role="list"
                                className={classNames(
                                    tier.featured
                                    ? 'divide-gray-900/5 border-gray-900/5 text-gray-600'
                                    : 'divide-white/5 border-white/5 text-white',
                                    '-my-2 divide-y border-t text-sm leading-6 lg:border-t-0'
                                )}
                                >
                                {tier.mainFeatures.map((mainFeature) => (
                                    <li key={mainFeature} className="flex gap-x-3 py-2">
                                    <CheckIcon
                                        className={classNames(
                                        tier.featured ? 'text-indigo-600' : 'text-gray-500',
                                        'h-6 w-5 flex-none'
                                        )}
                                        aria-hidden="true"
                                    />
                                    {mainFeature}
                                    </li>
                                ))}
                                </ul>
                            </div>
                            </div>
                        </div>
                        ))}
                    </div> */}
            </div>
          </div>
          <div className="relative bg-gray-50">
            <div className="mx-auto max-w-7xl px-6 pb-12 sm:pb-16 lg:px-8">
              {/* Feature comparison (up to lg) */}
              <section aria-labelledby="mobile-comparison-heading" className="lg:hidden">
                {/* <h2 id="mobile-comparison-heading" className="sr-only">
                            Feature comparison
                            </h2> */}

                <div className="mx-auto max-w-2xl space-y-16">
                  {tiers.map((tier) => (
                    <div key={tier.id} className="border-t border-gray-900/10 ">
                      <div
                        className={classNames(
                          tier.featured ? 'border-art-orange' : 'border-transparent',
                          '-mt-px w-72 border-t-2 md:w-80 '
                        )}
                      >
                        <h3
                          className={classNames(
                            tier.featured ? 'text-art-orange' : 'text-art-marron-fonce rounded-b-lg bg-art-beige-fonce py-2 text-xl font-bold w-full px-4',
                            'leading-6'
                          )}
                        >
                          {tier.name}
                        </h3>
                        <p className="mt-1 text-sm leading-6 text-gray-600">{tier.description}</p>
                      </div>

                      <div className="mt-10 space-y-10">
                        {sections.map((section) => (
                          <div key={section.name}>
                            <h4 className="font-semibold leading-6  p-2 px-6 text-base bg-art-beige text-art-bleu rounded-full">{section.name}</h4>
                            <div className="relative mt-6">
                              {/* Fake card background */}
                              <div
                                aria-hidden="true"
                                className="absolute inset-y-0 right-0 hidden w-1/2 rounded-lg bg-white shadow-sm sm:block"
                              />

                              <div
                                className={classNames(
                                  tier.featured ? 'ring-2 ring-art-orange' : 'ring-1 ring-gray-900/10',
                                  'relative rounded-lg bg-white shadow-sm sm:rounded-none sm:bg-transparent sm:shadow-none sm:ring-0'
                                )}
                              >
                                <dl className="divide-y divide-gray-200 text-sm leading-6">
                                  {section.features.map((feature) => (
                                    <div
                                      key={feature.name}
                                      className="flex items-center justify-between px-4 py-3 sm:grid sm:grid-cols-2 sm:px-0"
                                    >
                                      <dt className="pr-4 text-art-marron-fonce">{feature.name}</dt>
                                      <dd className="flex items-center justify-end sm:justify-center sm:px-4 whitespace-nowrap">
                                        {typeof feature.tiers[tier.name] === 'string' ? (
                                          <span
                                            className={tier.featured ? 'font-semibold text-art-orange' : 'text-art-marron-fonce'}
                                          >
                                            {feature.tiers[tier.name]}
                                          </span>
                                        ) : (
                                          <>
                                            {feature.tiers[tier.name] === true ? (
                                              <CheckIcon className="mx-auto h-5 w-5 text-art-orange" aria-hidden="true" />
                                            ) : (
                                              <XIcon className="mx-auto h-5 w-5 text-gray-400" aria-hidden="true" />
                                            )}

                                            <span className="sr-only">
                                              {feature.tiers[tier.name] === true ? 'Yes' : 'No'}
                                            </span>
                                          </>
                                        )}
                                      </dd>
                                    </div>
                                  ))}
                                </dl>
                              </div>

                              {/* Fake card border */}
                              <div
                                aria-hidden="true"
                                className={classNames(
                                  tier.featured ? 'ring-2 ring-art-orange' : 'ring-1 ring-gray-900/10',
                                  'pointer-events-none absolute inset-y-0 right-0 hidden w-1/2 rounded-lg sm:block'
                                )}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="flex mx-auto max-w-7xl py-4 sm:pb-16 justify-end">
                        <a
                          href="/contact/"
                          className="align-right ml-4 rounded-md bg-art-orange px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-art-bleu focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-art-orange"
                        >
                          Demandes de devis de déménagement
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </section>





              {/* Feature comparison (lg+) */}
              <section aria-labelledby="comparison-heading" className="hidden lg:block">
                {/* <h2 id="comparison-heading" className="sr-only">
                            Feature comparison
                            </h2> */}

                <div className="grid grid-cols-10 gap-x-8 border-t border-gray-900/10 before:block pb-12">
                  <div className="col-span-3">

                  </div>
                  {tiers.map((tier) => (
                    <div key={tier.id} aria-hidden="true" className="-mt-px text-center rounded-b-lg bg-art-beige-fonce py-2 text-xl font-bold shadow-xl">
                      <div
                        className={classNames(
                          tier.featured ? 'border-art-orange' : 'border-transparent  ',
                          'border-t-2 '
                        )}
                      >
                        <p
                          className={classNames(
                            tier.featured ? 'text-art-orange' : 'text-art-marron-fonce',
                            'leading-6'
                          )}
                        >
                          {tier.name}
                        </p>
                        <p className="mt-1 text-sm leading-6 text-gray-600">{tier.description}</p>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="-mt-6 space-y-16">
                  {sections.map((section) => (
                    <div key={section.name}>
                      <h3 className="font-semibold leading-6 p-4 px-8 text-2xl bg-art-beige text-art-bleu rounded-full shadow-xl">{section.name}</h3>
                      <div className="relative -mx-8 mt-10">
                        {/* Fake card backgrounds */}
                        <div
                          className="absolute inset-x-8 inset-y-0 grid grid-cols-10 gap-x-8 before:block"
                          aria-hidden="true"
                        >
                          <div className="col-span-3"></div>
                          <div className="h-full w-full rounded-lg bg-white shadow-sm" />
                          <div className="h-full w-full rounded-lg bg-white shadow-sm" />
                          <div className="h-full w-full rounded-lg bg-white shadow-sm" />
                          <div className="h-full w-full rounded-lg bg-white shadow-sm" />
                          <div className="h-full w-full rounded-lg bg-white shadow-sm" />
                          <div className="h-full w-full rounded-lg bg-white shadow-sm" />
                        </div>

                        <table className="table-fixed relative w-full border-separate border-spacing-x-8">
                          <thead>
                            <tr className="text-left">
                              <th colspan="4" scope="col-span-3">
                                <span className="sr-only">Feature</span>
                              </th>
                              {tiers.map((tier) => (
                                <th key={tier.id} scope="col">
                                  <span className="sr-only">{tier.name} tier</span>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {section.features.map((feature, featureIdx) => (
                              <tr key={feature.name}>
                                <th
                                  colspan="4"
                                  scope="row"
                                  className="w-1/10 py-3 pr-4 text-left text-base font-normal leading-6 text-art-marron-fonce"
                                >
                                  {feature.name}
                                  {featureIdx !== section.features.length - 1 ? (
                                    <div className="absolute inset-x-8 mt-3 h-px bg-gray-200" />
                                  ) : null}
                                </th>
                                {tiers.map((tier) => (
                                  <td key={tier.id} className="relative w-1/10 py-0 text-center">
                                    <span className="relative h-full w-full py-3">
                                      {typeof feature.tiers[tier.name] === 'string' ? (
                                        <span
                                          className={classNames(
                                            tier.featured ? 'font-semibold text-art-orange' : 'text-art-marron-fonce',
                                            'text-base leading-6'
                                          )}
                                        >
                                          {feature.tiers[tier.name]}
                                        </span>
                                      ) : (
                                        <>
                                          {feature.tiers[tier.name] === true ? (
                                            <CheckIcon className="mx-auto h-7 w-7 text-art-orange" aria-hidden="true" />
                                          ) : (
                                            <XIcon className="mx-auto h-7 w-7 text-gray-400" aria-hidden="true" />
                                          )}

                                          <span className="sr-only">
                                            {feature.tiers[tier.name] === true ? 'Yes' : 'No'}
                                          </span>
                                        </>
                                      )}
                                    </span>
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        {/* Fake card borders */}
                        <div
                          className="pointer-events-none absolute inset-x-8 inset-y-0 grid grid-cols-10 gap-x-8 before:block"
                          aria-hidden="true"
                        >
                          <div className="col-span-3"></div>
                          {tiers.map((tier) => (
                            <div
                              key={tier.id}
                              className={classNames(
                                tier.featured ? 'ring-2 ring-art-orange' : 'ring-1 ring-gray-900/10',
                                'rounded-lg'
                              )}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="flex mx-auto max-w-7xl pb-8 sm:pb-16 justify-end">
                    <a
                      href="/contact/"
                      className="align-right ml-4 rounded-md bg-art-orange px-3.5 py-2.5 text-base font-semibold text-white shadow-sm hover:bg-art-bleu focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-art-orange"
                    >
                      Demandes de renseignements ou devis de déménagement
                    </a>
                  </div>


                </div>
              </section>



              <h2 className="mx-auto max-w-4xl text-center text-3xl font-bold tracking-tight text-art-marron-fonce sm:text-4xl lg:text-5xl">
                Nos formules de déménagement sur mesure
              </h2>
              <ul className="mt-6 space-y-3 text-xl leading-6 text-art-marron-fonce">
                <li className="flex gap-x-3">
                  <CheckCircleIcon className="h-6 w-5 flex-none text-art-orange" aria-hidden="true" />
                  Déménagement particulier
                </li>
                <li className="flex gap-x-3">
                  <CheckCircleIcon className="h-6 w-5 flex-none text-art-orange" aria-hidden="true" />
                  Déménagement professionnel
                </li>
                <li className="flex gap-x-3">
                  <CheckCircleIcon className="h-6 w-5 flex-none text-art-orange" aria-hidden="true" />
                  <a className="underline" href="/prestations/garde-meubles/">
                    Garde-meubles
                  </a>
                </li>
                <li className="flex gap-x-3">
                  <CheckCircleIcon className="h-6 w-5 flex-none text-art-orange" aria-hidden="true" />
                  <a className="underline" href="/prestations/destruction-archives/">
                    Destruction d'archives
                  </a>
                </li>
                <li className="flex gap-x-3">
                  <CheckCircleIcon className="h-6 w-5 flex-none text-art-orange" aria-hidden="true" />
                  <a className="underline" href="/prestations/mise-decheterie/">
                    Mise en déchetterie
                  </a>
                </li>
                <li className="flex gap-x-3">
                  <CheckCircleIcon className="h-6 w-5 flex-none text-art-orange" aria-hidden="true" />
                  <a className="underline" href="/prestations/manutention-diverses/">
                    Manutentions diverses
                  </a>
                </li>
                <li className="flex gap-x-3">
                  <CheckCircleIcon className="h-6 w-5 flex-none text-art-orange" aria-hidden="true" />
                  <a className="underline" href="/prestations/international/">
                    Déménagement à l'international
                  </a>
                </li>
              </ul>


            </div>
          </div>
        </div>









      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  )
}
